// content/pageConfig.js
export const pageConfig = {
  createPage1: {
    reasoning: [
      {
        content:
          "British, Irish citizens, Commonwealth citizens with right to abode, and diplomats are eligible for housing assistance if they are habitually resident in the UK.\n\n For further information, check the Homelessness code of guidance for local authorities here: https://www.gov.uk/guidance/homelessness-code-of-guidance-for-local-authorities/chapter-7-eligibility-for-assistance",
      },
    ],
    heading: "",
    subtitle: "Are you any of the following?",
    is_multi: false,
    options: [
      {
        text: "British Citizen",
        value: "British",
        next: "habitual9",
      },
      {
        text: "Irish Citizen",
        value: "Irish",
        tooltip: false,
        next: "habitual9",
      },
      {
        text: "Commonwealth Citizen",
        value: "Commonwealth",
        tooltiptext: [
          {
            title: "Commonwealth Citizens",
            content:
              "A Commonwealth citizen is a citizen of a Commonwealth of Nations member state. Check here if your conuntry is a commonwealth member https://thecommonwealth.org/our-member-countries,",
          },
        ],
        next: "commonwealth",
      },
      {
        text: "Diplomat or their family member based in the UK",
        value: "Diplomat",

        next: "habitual9",
      },
      {
        text: "None of the above",
        value: "NA",

        next: "createPage2",
      },
    ],
  },
  habitual1: {
    heading: "",
    reasoning: [
      {
        content: "This is to check if you are habitual resident",
      },
      {
        title: "Habitual Residency",
        content:
          "'Habitual residence' means your main home is in the Common Travel Area and you do not have plans to live anywhere else.\n\nThe Common Travel Area means the UK, Republic of Ireland, Channel Islands, or Isle of Man.\n\nMost people have to be habitually resident to apply for council housing. This applies to British and Irish citizens as well as other passport holders.\n\n For more information, please check the habitual residence test here: https://www.gov.uk/guidance/homelessness-code-of-guidance-for-local-authorities/annex-1-the-habitual-residence-test",
      },
    ],
    subtitle: "Where do you currently live?",
    is_multi: false,
    options: [
      { text: "UK", value: "UK", next: "habitual2" },
      { text: "Republic of Ireland", value: "Ireland", next: "habitual2" },
      { text: "Isle of Man", value: "Isle Of Man", next: "habitual2" },
      { text: "Channel Islands", value: "Channel Island", next: "habitual2" },
      {
        text: "None of the Above",
        value: "NA",
        next: "DecisionPage",
        status: "not_eligible",
      },
    ],
  },
  habitual2: {
    heading: "",
    reasoning: [
      {
        content:
          "The Secretary of State considers that it is likely that applicants who have been resident in the UK, Channel Islands, the Isle of Man, or the Republic of Ireland continuously during the 2-year period prior to their housing application will be habitually resident.\n\nIn such cases, therefore, housing authorities may consider it unnecessary to make further enquiries to determine whether the person is habitually resident, unless there are other circumstances that need to be taken into account.\n\nA period of continuous residence in the UK, Channel Islands, the Isle of Man, or the Republic of Ireland might include periods of temporary absence.\n\nWhere 2 years’ continuous residency has not been established, housing authorities will need to conduct further enquiries to determine whether the applicant is habitually resident.\n\n For more information, please check section 7.22 of the Homelessness code of guidance for local authorities here: https://www.gov.uk/guidance/homelessness-code-of-guidance-for-local-authorities/chapter-7-eligibility-for-assistance",
      },
    ],
    subtitle:
      "Since how long have you been residing in your current place of residence?",
    is_multi: false,
    options: [
      { text: "Less than 2 years", value: "option1", next: "exemption1" },
      {
        text: "2 years or more",
        value: "option2",
        next: "DecisionPage",
        status: "eligible",
      },
    ],
  },
  exemption1: {
    reasoning: [
      {
        content:
          "People who are in the UK as a result of their deportation, expulsion or other removal by compulsion of law from another country are not subject to the requirement to be habitually resident in the UK, the Channel Islands, the Isle of Man or the Republic of Ireland. Such people will be eligible for assistance even if not habitually resident\n\nFor more information, please check section 7.18 of the Homelessness code of guidance for local authorities here: https://www.gov.uk/guidance/homelessness-code-of-guidance-for-local-authorities/chapter-7-eligibility-for-assistance",
      },
    ],
    heading: "",
    subtitle:
      "Are you in the UK as a result of deportation, expulsion or other removal by compulsion of law from another country to the UK?",
    is_multi: false,
    options: [
      {
        text: "Yes",
        value: "option1",
        next: "DecisionPage",
        status: "eligible",
      },
      {
        text: "No",
        value: "option2",
        next: "DecisionPage",
        status: "caseworker_1",
      },
    ],
  },
  createPage2: {
    identitypage: true,
    heading: "",
    reasoning: [
      {
        title: "EEA citizens who resided in the UK before 31 December 2020",
        content:
          "The Withdrawal Agreement protects the rights of EEA citizens and their family members who were lawfully residing, or frontier working, in the UK before 11pm on 31 December 2020, including access to social housing and homelessness assistance.\n\nThey will need to apply to the EU Settlement Scheme (EUSS) before the deadline of 30 June 2021 to continue residing in the UK.",
      },
      {
        title: "EEA citizens moving to the UK from 1 January 2021",
        content:
          "Newly arriving EEA citizens, moving to the UK from 1 January 2021, will have the same access to social housing and homelessness assistance as non-EEA migrants, unless they are a family member joining an EEA citizen who was residing in the UK by 31 December 2020.",
      },
      {
        content:
          "For further information, please check EEA Citizens’ Eligibility for Social Housing or Homelessness assistance here: https://assets.publishing.service.gov.uk/media/606349dbd3bf7f0c854ce227/LA_workshops_FAQ.pdf",
      },
      {
        title:
          "Persons subject to immigration control who are eligible for housing assistance",
        content:
          "A person with current leave to enter or remain in the UK with no condition or limitation, and who is habitually resident in the UK, the Channel Islands, the Isle of Man or the Republic of Ireland (Common Travel Area): such a person will have indefinite leave to enter or indefinite leave to remain and will be regarded as having settled status. However, where indefinite leave to enter or indefinite leave to remain status was granted as a result of an undertaking that a sponsor would be responsible for the applicant’s maintenance and accommodation, the applicant must have been resident in the Common Travel Area for 5 years since the date of entry – or the date of the sponsorship undertaking, whichever is later – for the applicant to be eligible. Where a sponsor has (or, if there was more than one sponsor, all of the sponsors have) died within the first 5 years, the applicant will be eligible for housing assistance.\n\n For more information, please check section 7.14 of the Homelessness code of guidance for local authorities here: https://www.gov.uk/guidance/homelessness-code-of-guidance-for-local-authorities/chapter-7-eligibility-for-assistance",
      },
    ],

    subtitle: "Which of the following applies to you?",
    is_multi: false,
    options: [
      {
        text: "EEA national with a presettled status in the UK",
        value: "EEA_presettled",
        tooltiptext: [
          {
            title: "EEA National",
            content:
              "The EEA includes EU countries and also Iceland, Liechtenstein, and Norway. It allows them to be part of the EU’s single market.\n\nThe EU countries are:\n\nAustria, Belgium, Bulgaria, Croatia, Republic of Cyprus, Czech Republic, Denmark, Estonia, Finland, France, Germany, Greece, Hungary, Ireland, Italy, Latvia, Lithuania, Luxembourg, Malta, Netherlands, Poland, Portugal, Romania, Slovakia, Slovenia, Spain, and Sweden.\n\nSwitzerland and the Single Market\n\nSwitzerland is not an EU or EEA member but is part of the single market. This means Swiss nationals have the same rights to live and work in the UK as other EEA nationals.",
          },
          {
            title: "Pre-settled status",
            content:
              "Pre-settled status is a form of limited leave to remain in the UK granted to EEA nationals and their family members under the EU Settlement Scheme.\n\nPeople with pre-settled status are entitled to:\n\n- Remain in the UK after 30 June 2021\n- Apply for settled status once they have lived in the UK continuously for five years\n\nPre-settled status does not automatically entitle people to public funds, including homelessness assistance. Homeless applicants with pre-settled status are eligible if they meet additional conditions.\n\n Please check https://england.shelter.org.uk/professional_resources/legal/homelessness_applications/eligibility_for_homeless_assistance/people_with_eu_pre-settled_status_eligibility_for_homeless_assistance for further information.",
          },
        ],
        next: "rejection1",
      },
      {
        text: "EEA national or family member with settled status in the UK",
        value: "EEA_settled",
        brp: true,
        tooltiptext: [
          {
            title: "EEA National",
            content:
              "The EEA includes EU countries and also Iceland, Liechtenstein, and Norway. It allows them to be part of the EU’s single market.\n\nThe EU countries are:\n\nAustria, Belgium, Bulgaria, Croatia, Republic of Cyprus, Czech Republic, Denmark, Estonia, Finland, France, Germany, Greece, Hungary, Ireland, Italy, Latvia, Lithuania, Luxembourg, Malta, Netherlands, Poland, Portugal, Romania, Slovakia, Slovenia, Spain, and Sweden.\n\nSwitzerland and the Single Market\n\nSwitzerland is not an EU or EEA member but is part of the single market. This means Swiss nationals have the same rights to live and work in the UK as other EEA nationals.",
          },
          {
            title: "Settled status",
            content:
              "Settled status is equivalent to indefinite leave to enter or remain in the UK under the Immigration Rules.\n\nAn EEA citizen may qualify for settled status if they arrived before 1 January 2021 and have since been resident in the UK for a continuous five-year period.\n\nIn certain circumstances, such as retirement or permanent incapacity, it may be possible to qualify for settled status before five years.\n\n Please check https://england.shelter.org.uk/professional_resources/legal/homelessness_applications/eligibility_for_homeless_assistance/people_from_abroad_who_are_eligible_for_homeless_assistance for further information",
          },
        ],
        next: "sponsorship1",
      },
      {
        text: "National of any other country with indefinite leave to remain in the UK (Settlement)",
        brp: true,
        value: "othernational_settled",
        next: "sponsorship1",
      },
      {
        text: "None of the above applies to me",
        value: "NA",
        next: "createPage3",
      },
    ],
  },
  sponsorship1: {
    reasoning: [
      {
        title: "",
        content:
          "Sponsored immigrants with indefinite leave to remain may not be eligible. A sponsored immigrant is a person who has been granted leave to remain on the basis of an undertaking from a sponsor, usually a relative. It must have been a specific condition of entry that a formal written undertaking was given that they would be maintained and accommodated without recourse to public funds.\n\nA sponsored immigrant is eligible if either:\n\n- They have been in the Common Travel Area for more than five years since they entered the UK or the start of the sponsor’s undertaking, whichever is later\n- The sponsor has died.\n\n For further information, please check here https://england.shelter.org.uk/professional_resources/legal/homelessness_applications/eligibility_for_homeless_assistance/people_from_abroad_who_are_eligible_for_homeless_assistance",
      },
    ],
    heading: "",
    subtitle: "Is your settled status based on a sponsorship?",
    is_multi: false,
    options: [
      { text: "Yes", value: "sponsorship_yes", next: "habitual3" },
      { text: "No", value: "sponsorship_no", next: "habitual1" },
    ],
  },
  habitual3: {
    reasoning: [
      {
        content:
          "Sponsored immigrants with indefinite leave to remain may not be eligible. A sponsored immigrant is a person who has been granted leave to remain on the basis of an undertaking from a sponsor, usually a relative. It must have been a specific condition of entry that a formal written undertaking was given that they would be maintained and accommodated without recourse to public funds.\n\nA sponsored immigrant is eligible if either:\n\n- They have been in the Common Travel Area for more than five years since they entered the UK or the start of the sponsor’s undertaking, whichever is later\n\n- The sponsor has died\n\nFor further information, please check here https://england.shelter.org.uk/professional_resources/legal/homelessness_applications/eligibility_for_homeless_assistance/people_from_abroad_who_are_eligible_for_homeless_assistance",
      },
    ],
    heading: "",
    subtitle: "Where do you currently live?",
    is_multi: false,
    options: [
      { text: "UK", value: "UK", next: "habitual4" },
      { text: "Republic of Ireland", value: "Ireland", next: "habitual4" },
      { text: "Isle of Man", value: "Isle Of Man", next: "habitual4" },
      { text: "Channel Islands", value: "Channel Island", next: "habitual4" },
      {
        text: "None of the Above",
        value: "NA",
        next: "DecisionPage",
        status: "not_eligible",
      },
    ],
  },
  habitual4: {
    reasoning: [
      {
        content:
          "Sponsored immigrants with indefinite leave to remain may not be eligible. A sponsored immigrant is a person who has been granted leave to remain on the basis of an undertaking from a sponsor, usually a relative. It must have been a specific condition of entry that a formal written undertaking was given that they would be maintained and accommodated without recourse to public funds.\n\nA sponsored immigrant is eligible if either:\n\n- They have been in the Common Travel Area for more than five years since they entered the UK or the start of the sponsor’s undertaking, whichever is later\n\n- The sponsor has died\n\nFor further information, please check here https://england.shelter.org.uk/professional_resources/legal/homelessness_applications/eligibility_for_homeless_assistance/people_from_abroad_who_are_eligible_for_homeless_assistance",
      },
    ],
    heading: "",
    subtitle:
      "Since how long have you been residing in your current place of residence?",
    is_multi: false,
    options: [
      { text: "Less than 5 years", value: "less_than_5", next: "exemption1" },
      {
        text: "5 years or more",
        value: "5_or_more",
        next: "DecisionPage",
        status: "eligible",
      },
    ],
  },
  rejection1: {
    reasoning: [
      {
        title: "Rights to reside which did not confer eligibility",
        content:
          "An EEA national must have had a right to reside in the UK to be eligible for homelessness assistance. However, not all EEA nationals with a right to reside were eligible.\n\nThe following categories of EEA nationals with a right to reside were not eligible for homelessness assistance:\n\n- People whose only right to reside was as a jobseeker\n\n- EEA nationals and their family members exercising the three-month initial right to reside\n\n- From 8 November 2012, a non-EEA national who was the primary carer of a dependent British citizen. This is often known as a 'Zambrano' right to reside\n\n- EEA nationals whose only right to reside in the UK was as a student or as a self-sufficient person, if their homelessness application was found to be a breach of their student agreement or self-sufficiency",
      },
      {
        content:
          "Please check here for further information https://england.shelter.org.uk/professional_resources/legal/homelessness_applications/eea_nationals_eligibility_for_homelessness_assistance_before_brexit/eea_nationals_not_eligible_for_assistance_rules_before_brexit",
      },
    ],
    heading: "",
    subtitle: "Does any of the following apply to you?",
    is_multi: false,
    options: [
      {
        text: "My right to reside in the UK is only due to my jobseeker status",
        value: "sole_jobseeker",
        tooltiptext: [
          {
            title: "Right to reside",
            content:
              "‘Right to reside’ means you have the right to live in the UK.\n\nYou have a right to reside in the UK if any of the following is true:\n\n- You have ‘right of abode’ in the UK, if you’re a British citizen\n- You’re a citizen of Ireland\n- You have pre-settled or settled status through the EU Settlement Scheme\n- You have indefinite leave to enter (ILE) or remain in the UK (ILR)\n- You’re exempt from immigration control",
          },
          {
            title: "Jobseeker",
            content:
              "A jobseeker was someone who entered the UK to look for work, or who was looking for work in the UK immediately after previously exercising a right to reside as a worker, self-employed person, self-sufficient person, or a student, and who could not demonstrate that they had a genuine chance of being engaged in employment.",
          },
        ],
        next: "DecisionPage",
        status: "not_eligible_8",
      },
      {
        text: "I only have an initial right to reside in the UK",
        value: "initial_3months",
        tooltiptext: [
          {
            title: "Initial Right",
            content:
              "Any EEA national and their family members had an initial right to reside in the UK for three months, provided they did not become a burden on the host Member State.",
          },
        ],
        next: "DecisionPage",
        status: "not_eligible_8",
      },
      {
        text: "None of the above applies to me",
        value: "NA",
        next: "createPage4",
      },
    ],
  },
  createPage4: {
    reasoning: [
      {
        content:
          "Under retained EU law, EEA nationals are eligible if, at the time of application, they meet the conditions for being treated as a:\n\n- Worker or self-employed person\n\n- Person with permanent right to reside\n\n- Primary carer of a child in education where one of the child's parents is or was an EEA worker or self-employed person ('Baumbast' right)",
      },
    ],
    heading: "",
    subtitle: "Are you a person or family member of a person who is",
    is_multi: false,
    options: [
      {
        text: "a worker in the UK?",
        value: "worker",
        tooltiptext: [
          {
            title: "Worker",
            content:
              "A person is generally classed as a ‘worker’ if:\n\n- They have a contract or other arrangement to do work or services personally for a reward (a contract can be written or unwritten)\n- Their reward is for money or a benefit in kind, for example the promise of a contract or future work.",
          },
        ],
        next: "DecisionPage",
        status: "eligible",
      },
      {
        text: "self-employed in the UK?",
        value: "self_employed",
        tooltiptext: [
          {
            title: "Self employed",
            content:
              "A person is self-employed if they run their business for themselves and take responsibility for its success or failure.\n\nSelf-employed workers are not paid through PAYE, and they do not have the rights and responsibilities of an employee.",
          },
        ],
        next: "DecisionPage",
        status: "eligible",
      },
      {
        text: "frontier worker in the UK before 31 December 2020?",
        value: "frontier",
        tooltiptext: [
          {
            title: "Frontier worker",
            content:
              "You need to get a Frontier Worker permit if you want to work in the UK but live in another country.\n\nYou may be eligible if all of the following apply:\n\n- You’re from the EU, Switzerland, Norway, Iceland, or Liechtenstein.\n- You live outside of the UK.\n- You began working in the UK by 31 December 2020.\n\nYou must usually have worked in the UK at least once every 12 months since you started working here. You may still be able to apply if you’ve had periods of unemployment or were unable to work during this time.\n\nIf you want to work in the UK from 1 January 2021, and were not working here before, you’ll need to apply for a visa.",
          },
        ],
        next: "DecisionPage",
        status: "eligible",
      },
      {
        text: "None of the above applies to me",
        value: "NA",
        next: "habitual1",
      },
    ],
  },
  createPage3: {
    reasoning: [
      {
        title:
          "Persons ineligible under certain provisions by virtue of Schedule 3 to the Nationality, Immigration and Asylum Act 2002",
        content:
          "The following classes of persons are ineligible:\n\n- A person who has refugee status abroad;\n- A person who was (but is no longer) an asylum seeker and who fails to co-operate with removal directions issued in respect of them;\n- A person who is in the UK in breach of the immigration laws (within the meaning of section 50A of the British Nationality Act 1981) and is not an asylum seeker;\n- Certain persons who are failed asylum seekers with dependent children, where the Secretary of State has certified that, in their opinion, such a person has failed without reasonable excuse to take reasonable steps to leave the UK voluntarily or place themselves in a position where they are able to leave the UK voluntarily, and that person has received the Secretary of State’s certificate more than 14 days previously;\n- A person who is the dependant of a person who falls within class (a) above.\n\n For more information, please check Section 54 of, and Schedule 3 to, the Nationality, Immigration and Asylum Act 2002 here https://www.legislation.gov.uk/ukpga/2002/41/schedule/3",
      },
    ],
    subtitle: "Are you a person/dependant of a person who",
    is_multi: false,
    options: [
      {
        text: "has a refugee status abroad?",
        value: "refugee_abroad",
        next: "DecisionPage",
        status: "not_eligible_9",
      },
      {
        text: "former asylum seeker who has been instructed to move but failed to do so (failed asylum seeker)?",
        tooltiptext: [
          {
            title: "Failed asylum seeker",
            content:
              "You are considered to be a ‘refused’ or ‘failed’ asylum seeker if your claim for asylum, or claim under Article 3 of the European Convention on Human Rights, has been refused and any subsequent appeals have been unsuccessful.\n\nWhere an asylum seeker who has been dispersed receives a negative decision on their application/appeal for refugee status, they usually have to leave UK Visas and Immigration (UKVI) accommodation within 21 days of receiving the decision.\n\n Please check https://england.shelter.org.uk/professional_resources/legal/housing_options/asylum_seekers_accommodation_options/support_after_an_asylum_application_is_refused for further information",
          },
        ],
        value: "failed_asylum",
        next: "DecisionPage",
        status: "not_eligible_9",
      },
      {
        text: "is in the UK in breach of UK immigration laws?",
        value: "breach",
        next: "DecisionPage",
        status: "not_eligible_9",
      },
      {
        text: "failed asylum seeker with dependant children?",
        value: "failed_asylum_children",
        next: "DecisionPage",
        status: "not_eligible_9",
      },
      {
        text: "None of the above applies to me",
        value: "NA",
        next: "createPage5",
      },
    ],
  },
  createPage5: {
    subtitle: "Are you a person who",
    reasoning: [
      {
        content:
          "People from abroad who are subject to immigration control are not eligible for assistance unless they fall into a class set out in the Allocation of Housing and Homelessness (Eligibility) (England) Regulations 2006.",
      },
      {
        title: "Refugees",
        content:
          "A person who has been granted either limited or indefinite leave to remain as a refugee is eligible for homelessness assistance.\n\nThey are referred to as Class A in the regulations.\n\nRefugees are usually granted five years' limited leave to remain. At the end of the five years, they are considered for indefinite leave to remain.\n\nIf an asylum seeker wins an asylum appeal and is found to be a refugee, they do not become eligible until the Home Office accepts the decision and issues the necessary documents.",
      },
      {
        title: "Migrant victims of domestic abuse concession",
        content:
          "The Migrant victims of domestic abuse concession is a form of exceptional leave to remain for some victims of domestic abuse. This was previously known as the destitution domestic violence (DDV) concession.\n\nThe concession gives three months' limited leave to remain with access to public funds while the Home Office considers their application for permanent leave to remain.",
      },
      {
        title: "People with humanitarian protection",
        content:
          "A person with humanitarian protection under immigration rules is eligible for homelessness assistance.\n\nHumanitarian protection is granted to people who are not recognised as refugees, but it is not safe for them to return to their country of origin.\n\nThey are referred to as Class D in the regulations.",
      },
      {
        title:
          "Victims of human trafficking or modern slavery granted leave to remain",
        content:
          "A person is eligible for homelessness assistance if they have been given limited leave under Appendix Temporary Permission to Stay for Victims of Human Trafficking or Slavery of the Immigration Rules. This applies from 30 January 2023.\n\nThey are referred to as Class P in the regulations.",
      },
      {
        title: "Family members of a relevant person of Northern Ireland",
        content:
          "A person with limited leave to remain in the UK under Appendix EU of the Immigration Rules is eligible if they:\n\n- Are a family member of a relevant person of Northern Ireland\n- Would be eligible as a family member of an EEA worker or self-employed person if the relevant person of Northern Ireland was treated as an EEA national\n\nThey are treated as if they were the family member of an EEA worker or self-employed person eligible in accordance with regulation 4 of the Eligibility Regulations.\n\nThey are referred to as Class J in the regulations.",
      },
      {
        title: "Relevant Afghan citizens",
        content:
          "Afghan citizens are eligible for homelessness assistance if they have limited leave to remain in the UK under either the Afghan Relocations and Assistance Policy or the previous (ex gratia) scheme for locally employed staff in Afghanistan.",
      },
      {
        content:
          "For more information, please check Shelter's page about People from abroad who are eligible for homeless assistance here https://england.shelter.org.uk/professional_resources/legal/homelessness_applications/eligibility_for_homeless_assistance/people_from_abroad_who_are_eligible_for_homeless_assistance",
      },
    ],

    is_multi: false,
    options: [
      {
        text: "has a refugee status in the UK?",
        tooltiptext: [
          {
            title: "Refugee status",
            content:
              "To stay in the UK as a refugee, you must be unable to live safely in any part of your own country because you fear persecution there.\n\nThis persecution must be because of:\n\n- Your race\n- Your religion\n- Your nationality\n- Your political opinion\n- Anything else that puts you at risk because of the social, cultural, religious, or political situation in your country, for example, your gender, gender identity, or sexual orientation\n\nYou must have failed to get protection from authorities in your own country.\n\n Check https://www.gov.uk/claim-asylum/eligibility for further information.",
          },
        ],
        value: "refugee_home",
        next: "habitual5",
      },
      {
        text: "has humanitarian protection?",
        value: "humanitarian",
        tooltiptext: [
          {
            title: "Humanitarian Protection",
            content:
              "An asylum seeker who does not meet the criteria for a grant of refugee status will be considered for humanitarian protection.\n\nUnder paragraph 339C of the Immigration Rules, humanitarian protection must be granted to eligible claimants where there are substantial grounds for believing that there is a real risk of serious harm for the claimant on return to their country of origin. Please check https://www.gov.uk/settlement-refugee-or-humanitarian-protection for further information.",
          },
        ],
        next: "habitual5",
      },
      {
        text: "limited leave to enter and remain in the UK as the family member of a ‘relevant person of Northern Ireland’?",
        value: "relevant_ne",
        tooltiptext: [
          {
            title: "Relevant person of Northern Ireland",
            content:
              "You can apply for an EU Settlement Scheme family permit if you are an eligible family member of a person of Northern Ireland.\n\nThe person of Northern Ireland who is your family member must:\n\n- Be a British, Irish, or dual British and Irish citizen\n- Have been born in Northern Ireland\n- At the time of their birth, have had at least one parent who held British, Irish, or dual citizenship (or had no restriction on how long they could stay in Northern Ireland)\n- Have been living in the UK since before 31 December 2020\n- Have settled or pre-settled status - or show that they were eligible for it if they had applied (or been able to apply) by 30 June 2021.\n\nPlease check https://www.gov.uk/family-permit/apply-joining-person-of-northern-ireland for further information.",
          },
        ],
        next: "habitual5",
      },
      {
        text: "is in the UK on the basis of the Afghan Relocations and Assistance Policy?",
        value: "ARAP",
        tooltiptext: [
          {
            title: "Afghan Relocations and Assistance Policy",
            content:
              "The Afghan Relocations and Assistance Policy (ARAP) is for Afghan citizens who worked for or with the UK Government in Afghanistan in exposed or meaningful roles and may include an offer of relocation to the UK for those deemed eligible by the Ministry of Defence and who are deemed suitable for relocation by the Home Office.\n\nAfghan citizens who are eligible for relocation to the UK under the ARAP may relocate with a partner, dependent children, and additional family members who are also deemed eligible for relocation under the ARAP by the Ministry of Defence and suitable for relocation by the Home Office.\n\n Please check https://www.gov.uk/government/publications/afghan-relocations-and-assistance-policy/afghan-relocations-and-assistance-policy-information-and-guidance for further information.",
          },
        ],
        next: "habitual5",
      },
      {
        text: "had to move to the UK from Ukraine part of the Russian invasion of Ukraine?",
        value: "Ukraine",
        next: "habitual5",
      },
      {
        text: "is a victim of human-traficking/slavery and granted a permission to stay in the UK?’",
        value: "slavery",
        next: "habitual5",
      },
      {
        text: "is a victim of transnational marriage abandonment and granted a permission to stay in the UK?",
        value: "marriage_issue",
        tooltiptext: [
          {
            title: "Transnational Marriage Abandonment",
            content:
              "Victims of transnational marriage abandonment are individuals who have been abandoned overseas due to domestic abuse, in this case by their spouse who is a British or Irish national or has indefinite leave.\n\nThese individuals often find themselves unable to return to the UK as they have been detained against their will, often through the withholding or destruction of their documents, causing their immigration status to lapse.\n\nThis means that they have been prevented from residing actually habitually in the UK through no fault of their own.\n\nPlease check https://www.gov.uk/government/publications/housing-benefit-adjudication-circulars-2024/a22024-victims-of-forced-marriage-and-victims-of-transnational-marriage-abandonment for further information.",
          },
        ],
        next: "habitual5",
      },
      {
        text: "None of the above applies to me",
        value: "NA",
        next: "createPage6",
      },
    ],
  },
  habitual5: {
    heading: "",
    reasoning: [
      {
        content: "This is to check if you are habitual resident",
      },
      {
        title: "Habitual Residency",
        content:
          "'Habitual residence' means your main home is in the Common Travel Area and you do not have plans to live anywhere else.\n\nThe Common Travel Area means the UK, Republic of Ireland, Channel Islands, or Isle of Man.\n\nMost people have to be habitually resident to apply for council housing. This applies to British and Irish citizens as well as other passport holders.\n\n For more information, please check the habitual residence test here: https://www.gov.uk/guidance/homelessness-code-of-guidance-for-local-authorities/annex-1-the-habitual-residence-test",
      },
    ],
    subtitle: "Where do you currently live?",
    is_multi: false,
    options: [
      { text: "UK", value: "UK", next: "habitual6" },
      { text: "Republic of Ireland", value: "Ireland", next: "habitual6" },
      { text: "Isle of Man", value: "Isle Of Man", next: "habitual6" },
      { text: "Channel Islands", value: "Channel Island", next: "habitual6" },
      {
        text: "None of the Above",
        value: "NA",
        next: "DecisionPage",
        status: "not_eligible_3",
      },
    ],
  },
  habitual6: {
    heading: "",
    reasoning: [
      {
        content:
          "The Secretary of State considers that it is likely that applicants who have been resident in the UK, Channel Islands, the Isle of Man, or the Republic of Ireland continuously during the 2-year period prior to their housing application will be habitually resident.\n\nIn such cases, therefore, housing authorities may consider it unnecessary to make further enquiries to determine whether the person is habitually resident, unless there are other circumstances that need to be taken into account.\n\nA period of continuous residence in the UK, Channel Islands, the Isle of Man, or the Republic of Ireland might include periods of temporary absence.\n\nWhere 2 years’ continuous residency has not been established, housing authorities will need to conduct further enquiries to determine whether the applicant is habitually resident.\n\n For more information, please check section 7.22 of the Homelessness code of guidance for local authorities here: https://www.gov.uk/guidance/homelessness-code-of-guidance-for-local-authorities/chapter-7-eligibility-for-assistance",
      },
    ],
    subtitle:
      "Since how long have you been residing in your current place of residence?",
    is_multi: false,
    options: [
      {
        text: "Less than 2 years",
        value: "option1",
        next: "DecisionPage",
        status: "caseworker_1",
      },
      {
        text: "2 years or more",
        value: "option2",
        next: "DecisionPage",
        status: "eligible",
      },
    ],
  },
  exemption2: {
    heading: "",
    subtitle:
      "Did you leave Ukraine in connection to the Russian invasion and do not need leave to enter or remain in the UK",
    is_multi: false,
    options: [
      {
        text: "Yes",
        value: "Ukraine_yes",
        next: "DecisionPage",
        status: "eligible",
      },
      {
        text: "No",
        value: "Ukraine_no",
        next: "DecisionPage",
        status: "caseworker",
      },
    ],
  },
  createPage6: {
    reasoning: [
      {
        title: "People with leave granted under Article 8 HRC",
        content:
          "A person granted limited leave to enter or remain in the UK on the grounds of 'respect for family or private life' under Article 8 of the Human Rights Convention is eligible if both the following apply:\n\n- The leave has been granted under para 276BE(1), para 276DG or Appendix FM of the Immigration Rules\n\n- The person is not subject to a 'no recourse to public funds' condition\n\nThey are referred to as Class G in the regulations.",
      },
      {
        title: "People from Hong Kong with British National (Overseas) status",
        content:
          "From 29 June 2021, a person with limited leave to enter or remain in the United Kingdom granted under the Appendix Hong Kong British National (Overseas) of the Immigration Rules is eligible for homelessness assistance, unless they are:\n\n- Subject to the 'no recourse to public funds' condition\n\n- Not habitually resident in the Common Travel Area",
      },
      {
        content:
          "For more information, please check Shelter's page about People from abroad who are eligible for homeless assistance here https://england.shelter.org.uk/professional_resources/legal/homelessness_applications/eligibility_for_homeless_assistance/people_from_abroad_who_are_eligible_for_homeless_assistance",
      },
    ],
    subtitle: "Are you a person who",
    is_multi: false,
    options: [
      {
        text: "has leave to enter or remain in the UK on family and private life grounds?",
        value: "family_life",
        tooltiptext: [
          {
            title: "Apply on the basis of your private life",
            content:
              "You can only apply on the basis of your private life if you’re already living in the UK.\n\nYou must be able to prove that you:\n\n- Are under 18 and you’ve lived in the UK continuously for at least 7 years, and it would be unreasonable to expect you to leave the UK\n- Are between 18 and 24 and you’ve lived continuously in the UK for more than half your life\n- Are 18 or over, have spent less than 20 years in the UK, and would have very significant problems living in the country you’d have to go to\n- Have been in the UK continuously for 20 years\n- Were born in the UK to a person who has permission to stay in the UK on the basis of their private life, or is applying for it\n\nYour family members can apply on the same application - you’ll be considered separately.\n\nPlease check https://www.gov.uk/uk-family-visa/private-life for further information.",
          },
        ],
        next: "publicFunds1",
      },
      {
        text: "has a British National Overseas Visa?",
        value: "BNO",
        tooltiptext: [
          {
            title: "British National(Overseas)",
            content:
              "Someone who was a British overseas territories citizen by connection with Hong Kong was able to register as a British national (overseas) before 1 July 1997.\n\nBritish overseas territories citizens from Hong Kong who did not register as British nationals (overseas) and had no other nationality or citizenship on 30 June 1997 became British overseas citizens on 1 July 1997.\n\nPlease check https://www.gov.uk/british-national-overseas-bno-visa for further information.",
          },
        ],

        next: "publicFunds1",
      },
      {
        text: "None of the above applies to me",
        value: "NA",
        next: "createPage7",
      },
    ],
  },
  publicFunds1: {
    reasoning: [
      {
        title: "Access to public funds",
        content:
          "This means you will be able to claim most benefits, tax credits, or housing assistance that are paid by the state.\n\nPublic funds for immigration purposes include the following:\n\n- Universal Credit\n\n- State Pension Credit\n\n- Personal Independence Payment\n\n- Attendance Allowance\n\n- Carer’s Allowance\n\n- Disability Living Allowance\n\n- Housing Benefit\n\n- Health in Pregnancy Grant\n\nThis is not an exhaustive list.\n\n For further information, please check the UK Government's guidance on public funds here https://www.gov.uk/government/publications/public-funds--2/public-funds.",
      },
    ],
    subtitle: "Do you have access to public funds?",
    is_multi: false,
    options: [
      { text: "Yes", value: "pf_yes", next: "habitual5" },
      {
        text: "No",
        value: "pf_no",
        next: "DecisionPage",
        status: "not_eligible_13",
      },
    ],
  },
  publicFunds2: {
    reasoning: [
      {
        title: "Access to public funds",
        content:
          "This means you will be able to claim most benefits, tax credits, or housing assistance that are paid by the state.\n\nPublic funds for immigration purposes include the following:\n\n- Universal Credit\n\n- State Pension Credit\n\n- Personal Independence Payment\n\n- Attendance Allowance\n\n- Carer’s Allowance\n\n- Disability Living Allowance\n\n- Housing Benefit\n\n- Health in Pregnancy Grant\n\nThis is not an exhaustive list.\n\n For further information, please check the UK Government's guidance on public funds here https://www.gov.uk/government/publications/public-funds--2/public-funds.",
      },
    ],
    subtitle: "Do you have access to public funds?",
    is_multi: false,
    options: [
      {
        text: "Yes",
        value: "pf_yes",
        next: "DecisionPage",
        status: "eligible",
      },
      {
        text: "No",
        value: "pf_no",
        next: "DecisionPage",
        status: "not_eligible_14",
      },
    ],
  },
  createPage7: {
    reasoning: [
      {
        title: "Relevant Afghan citizens",
        content:
          "Afghan citizens are also eligible from 16 September 2021 if all of the following apply:\n\n- They have another form of leave to enter or remain\n\n- Their leave is not subject to a 'no recourse to public funds' condition\n\n- They left Afghanistan in connection with the collapse of the government on 15th August 2021",
      },
      {
        title: "People who left Sudan because of the conflict",
        content:
          "From 15 May 2023, a person is eligible if they:\n\n- Were residing in Sudan before 15 April 2023\n\n- Left Sudan in connection with the violence which rapidly escalated on 15 April 2023\n\n- Have been granted leave in accordance with the immigration rules\n\n- Are not required to be supported without recourse to public funds\n\nThey do not need to be habitually resident.",
      },
      {
        title:
          "Certain people from Israel, the Occupied Palestinian Territories or Lebanon",
        content:
          "People who left Israel, the West Bank, the Gaza Strip, East Jerusalem, the Golan Heights, or Lebanon in connection with the Hamas terrorist attack in Israel on 7th October 2023 or the violence which rapidly escalated in the region following the attack are eligible for assistance if they:\n\n- Were residing in Israel, the West Bank, the Gaza Strip, East Jerusalem, the Golan Heights, or Lebanon immediately before 7 October 2023\n\n- Have been given leave in accordance with the Immigration Rules\n\n- Are not subject to a 'no recourse to public funds' condition\n\nThis does not cover people granted leave because of a sponsor, unless they have either been in the Common Travel Area for more than five years, or their sponsor has died.\n\nPeople granted leave under this provision are referred to as Class R in the regulations.\n\nThey do not have to be habitually resident in the Common Travel Area.",
      },
      {
        content:
          "For more information, please check Shelter's page about People from abroad who are eligible for homeless assistance here https://england.shelter.org.uk/professional_resources/legal/homelessness_applications/eligibility_for_homeless_assistance/people_from_abroad_who_are_eligible_for_homeless_assistance",
      },
    ],
    subtitle: "Are you a person who",
    is_multi: false,
    options: [
      {
        text: "moved to UK in connection with collapse of Afghanistan government?",
        value: "Afghan",
        next: "publicFunds2",
      },
      {
        text: "moved to the UK from Sudan in connection with the violence?",
        value: "Sudan",
        next: "publicFunds2",
      },
      {
        text: "moved to the UK in connection to the Hamas Terrorist Attack?",
        value: "Hamas",
        next: "publicFunds2",
      },
      {
        text: "None of the above applies to me",
        value: "NA",
        next: "DecisionPage",
        status: "not_eligible",
      },
    ],
  },
  habitual7: {
    heading: "",
    reasoning: [
      {
        content: "This is to check if you are habitual resident",
      },
      {
        title: "Habitual Residency",
        content:
          "'Habitual residence' means your main home is in the Common Travel Area and you do not have plans to live anywhere else.\n\nThe Common Travel Area means the UK, Republic of Ireland, Channel Islands, or Isle of Man.\n\nMost people have to be habitually resident to apply for council housing. This applies to British and Irish citizens as well as other passport holders.\n\n For more information, please check the habitual residence test here: https://www.gov.uk/guidance/homelessness-code-of-guidance-for-local-authorities/annex-1-the-habitual-residence-test",
      },
    ],
    subtitle: "Where do you currently live?",
    is_multi: false,
    options: [
      { text: "UK", value: "UK", next: "habitual8" },
      { text: "Republic of Ireland", value: "Ireland", next: "habitual8" },
      { text: "Isle of Man", value: "Isle Of Man", next: "habitual8" },
      { text: "Channel Islands", value: "Channel Island", next: "habitual8" },
      {
        text: "None of the Above",
        value: "NA",
        next: "DecisionPage",
        status: "not_eligible",
      },
    ],
  },
  habitual8: {
    heading: "",
    reasoning: [
      {
        content:
          "The Secretary of State considers that it is likely that applicants who have been resident in the UK, Channel Islands, the Isle of Man, or the Republic of Ireland continuously during the 2-year period prior to their housing application will be habitually resident.\n\nIn such cases, therefore, housing authorities may consider it unnecessary to make further enquiries to determine whether the person is habitually resident, unless there are other circumstances that need to be taken into account.\n\nA period of continuous residence in the UK, Channel Islands, the Isle of Man, or the Republic of Ireland might include periods of temporary absence.\n\nWhere 2 years’ continuous residency has not been established, housing authorities will need to conduct further enquiries to determine whether the applicant is habitually resident.\n\n For more information, please check section 7.22 of the Homelessness code of guidance for local authorities here: https://www.gov.uk/guidance/homelessness-code-of-guidance-for-local-authorities/chapter-7-eligibility-for-assistance",
      },
    ],
    subtitle:
      "Since how long have you been residing in your current place of residence?",
    is_multi: false,
    options: [
      {
        text: "Less than 2 years",
        value: "option1",
        next: "DecisionPage",
        status: "not_eligible",
      },
      {
        text: "2 years or more",
        value: "option2",
        next: "DecisionPage",
        status: "eligible",
      },
    ],
  },
  habitual9: {
    heading: "",
    reasoning: [
      {
        content: "This is to check if you are habitual resident",
      },
      {
        title: "Habitual Residency",
        content:
          "'Habitual residence' means your main home is in the Common Travel Area and you do not have plans to live anywhere else.\n\nThe Common Travel Area means the UK, Republic of Ireland, Channel Islands, or Isle of Man.\n\nMost people have to be habitually resident to apply for council housing. This applies to British and Irish citizens as well as other passport holders.\n\n For more information, please check the habitual residence test here: https://www.gov.uk/guidance/homelessness-code-of-guidance-for-local-authorities/annex-1-the-habitual-residence-test",
      },
    ],
    subtitle: "Where do you currently live?",
    is_multi: false,
    options: [
      { text: "UK", value: "UK", next: "habitual10" },
      { text: "Republic of Ireland", value: "Ireland", next: "habitual10" },
      { text: "Isle of Man", value: "Isle Of Man", next: "habitual10" },
      { text: "Channel Islands", value: "Channel Island", next: "habitual10" },
      {
        text: "None of the Above",
        value: "NA",
        next: "DecisionPage",
        status: "not_eligible_3",
      },
    ],
  },
  habitual10: {
    heading: "",
    reasoning: [
      {
        content:
          "The Secretary of State considers that it is likely that applicants who have been resident in the UK, Channel Islands, the Isle of Man, or the Republic of Ireland continuously during the 2-year period prior to their housing application will be habitually resident.\n\nIn such cases, therefore, housing authorities may consider it unnecessary to make further enquiries to determine whether the person is habitually resident, unless there are other circumstances that need to be taken into account.\n\nA period of continuous residence in the UK, Channel Islands, the Isle of Man, or the Republic of Ireland might include periods of temporary absence.\n\nWhere 2 years’ continuous residency has not been established, housing authorities will need to conduct further enquiries to determine whether the applicant is habitually resident.\n\n For more information, please check section 7.22 of the Homelessness code of guidance for local authorities here: https://www.gov.uk/guidance/homelessness-code-of-guidance-for-local-authorities/chapter-7-eligibility-for-assistance",
      },
    ],
    subtitle:
      "Since how long have you been residing in your current place of residence?",
    is_multi: false,
    options: [
      {
        text: "Less than 2 years",
        value: "option1",
        next: "DecisionPage",
        status: "caseworker_1",
      },
      {
        text: "2 years or more",
        value: "option2",
        next: "DecisionPage",
        status: "eligible",
      },
    ],
  },
  commonwealth: {
    identitypage: true,
    reasoning: [
      {
        title:
          "Persons subject to immigration control who are eligible for housing assistance",
        content:
          "A person with current leave to enter or remain in the UK with no condition or limitation, and who is habitually resident in the UK, the Channel Islands, the Isle of Man or the Republic of Ireland (Common Travel Area): such a person will have indefinite leave to enter or indefinite leave to remain and will be regarded as having settled status. However, where indefinite leave to enter or indefinite leave to remain status was granted as a result of an undertaking that a sponsor would be responsible for the applicant’s maintenance and accommodation, the applicant must have been resident in the Common Travel Area for 5 years since the date of entry – or the date of the sponsorship undertaking, whichever is later – for the applicant to be eligible. Where a sponsor has (or, if there was more than one sponsor, all of the sponsors have) died within the first 5 years, the applicant will be eligible for housing assistance.\n\n For more information, please check section 7.14 of the Homelessness code of guidance for local authorities here: https://www.gov.uk/guidance/homelessness-code-of-guidance-for-local-authorities/chapter-7-eligibility-for-assistance",
      },
      {
        title: "Commonwealth citizens with right to abode:",
        content:
          "Commonwealth citizens with right to abode are eligible for housing assistance if they are habitually resident in the UK.\n\n For further information, check the Homelessness code of guidance for local authorities here: https://www.gov.uk/guidance/homelessness-code-of-guidance-for-local-authorities/chapter-7-eligibility-for-assistance",
      },
    ],
    heading: "",
    subtitle: "Do you have any of the following?",
    is_multi: false,
    options: [
      {
        text: "I have indefinite leave to remain (settlement)",
        value: "ILR",
        brp: true,

        next: "sponsorship1",
      },
      {
        text: "I have limited leave to remain",
        tooltiptext: [
          {
            title: "Limited leave to remain",
            content: "",
          },
        ],
        value: "limited",
        next: "DecisionPage",
        status: "not_eligible_6",
      },
      {
        text: "I have right to abode in UK",
        value: "ROA",
        next: "habitual1",
        tooltiptext: [
          {
            title: "Right to abode",
            content:
              "A person has a right of abode if:\n\n- One of their parents was born in the UK and was a citizen of the UK and Colonies when they were born or adopted.\n- They were a Commonwealth citizen on 31 December 1982 and have continued to be a Commonwealth citizen.\n\nA female Commonwealth citizen can also have a right of abode if they have:\n\n- Been married to someone with the right of abode before 1 January 1983.\n- Not stopped being a Commonwealth citizen (even temporarily) at any point after 31 December 1982.\n\n Check https://www.gov.uk/right-of-abode/commonwealth-citizens for further information.",
          },
        ],
      },
      {
        text: "None of the above",
        value: "NA",
        next: "DecisionPage",
        status: "not_eligible_6",
      },
    ],
  },
};
export default pageConfig;
