import React from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import { useNavigation } from "../context/NavigationContext";
import { useGlobalState } from "../context/GlobalStateContext";
import Summary from "../components/Summary";
//import '../styles/Choicepage.css';
import "../styles/main.css";

const decisions = {
  eligible: {
    text: "You fulfil the minimum eligiblity requirements for social-housing assistance",
    additional_text:
      "Based on your answers, you fulfil the minimum eligiblity requirements for seeking social-housing assistance. <br><br>Please note that there might be additional requirements which you might need to fulfil. These can be based on where you live or where you want to apply for social housing.",
    todo: "You can go ahead and create a sharecode. This sharecode can be shared with your housing association or local authority.",
  },
  not_eligible: {
    text: "You do not meet the minimum requirements to be eligible for housing assistance",
    additional_text:
      "Based on your answers, you are not eligible for seeking housing assistance. You cannot create a sharecode. Sorry!",
    todo: "If you are facing homelessness, or need urgent housing support, or further advice, you can also contact organisations like Shelter or CitizenAdvice. <br>- Shelter England: <a href='https://england.shelter.org.uk/get_help' target='_blank' rel='noopener noreferrer' >https://england.shelter.org.uk/get_help</a> <br>- Shelter Scotland:  <a href='https://scotland.shelter.org.uk/about_us/contact_us' target='_blank' rel='noopener noreferrer'>https://scotland.shelter.org.uk/about_us/contact_us</a> <br>- Citizen Advice (England): <a href='https://www.citizensadvice.org.uk/about-us/information/chat-with-an-adviser-online/' target='_blank' rel='noopener noreferrer'>https://www.citizensadvice.org.uk/about-us/information/chat-with-an-adviser-online/</a> <br>- Citizen Advice Scotland <a href='https://www.citizensadvice.org.uk/scotland/housing/' target='_blank' rel='noopener noreferrer'>https://www.citizensadvice.org.uk/scotland/housing/</a> ",
  },
  not_eligible_3: {
    text: "You do not meet the minimum requirements to be eligible for housing assistance",
    additional_text:
      "You can only apply for housing assistance if you currently live in the United Kingdom, Republic of Ireland, Isle of Man or Channel Islands.",
    todo: "If you are facing homelessness, or need urgent housing support, or further advice, you can also contact organisations like Shelter or CitizenAdvice. <br>- Shelter England: <a href='https://england.shelter.org.uk/get_help' target='_blank' rel='noopener noreferrer' >https://england.shelter.org.uk/get_help</a> <br>- Shelter Scotland:  <a href='https://scotland.shelter.org.uk/about_us/contact_us' target='_blank' rel='noopener noreferrer'>https://scotland.shelter.org.uk/about_us/contact_us</a> <br>- Citizen Advice (England): <a href='https://www.citizensadvice.org.uk/about-us/information/chat-with-an-adviser-online/' target='_blank' rel='noopener noreferrer'>https://www.citizensadvice.org.uk/about-us/information/chat-with-an-adviser-online/</a> <br>- Citizen Advice Scotland <a href='https://www.citizensadvice.org.uk/scotland/housing/' target='_blank' rel='noopener noreferrer'>https://www.citizensadvice.org.uk/scotland/housing/</a> ",
  },
  not_eligible_6: {
    text: "You do not meet the minimum requirements to be eligible for housing assistance",
    additional_text:
      "You cannot apply for social housing if you are a commonwealth citizen but dont have ILR (settlement) or right to adobe in the UK. For more information, please check section 7.14 of the Homelessness code of guidance for local authorities here: <a href='https://www.gov.uk/guidance/homelessness-code-of-guidance-for-local-authorities/chapter-7-eligibility-for-assistance' target='_blank' rel='noopener noreferrer'>https://www.gov.uk/guidance/homelessness-code-of-guidance-for-local-authorities/chapter-7-eligibility-for-assistance</a>",
    todo: "If you are facing homelessness, or need urgent housing support, or further advice, you can also contact organisations like Shelter or CitizenAdvice. <br>- Shelter England: <a href='https://england.shelter.org.uk/get_help' target='_blank' rel='noopener noreferrer' >https://england.shelter.org.uk/get_help</a> <br>- Shelter Scotland:  <a href='https://scotland.shelter.org.uk/about_us/contact_us' target='_blank' rel='noopener noreferrer'>https://scotland.shelter.org.uk/about_us/contact_us</a> <br>- Citizen Advice (England): <a href='https://www.citizensadvice.org.uk/about-us/information/chat-with-an-adviser-online/' target='_blank' rel='noopener noreferrer'>https://www.citizensadvice.org.uk/about-us/information/chat-with-an-adviser-online/</a> <br>- Citizen Advice Scotland <a href='https://www.citizensadvice.org.uk/scotland/housing/' target='_blank' rel='noopener noreferrer'>https://www.citizensadvice.org.uk/scotland/housing/</a> ",
  },
  not_eligible_8: {
    text: "You do not meet the minimum requirements to be eligible for housing assistance at the moment",
    additional_text:
      "As a EEA national with a presettled status in the UK, you cannot apply for social housing if <br>- your right to reside in the UK is only due to my jobseeker status or <br> - you only have an initial right to reside in the UK",
    todo: "If you are facing homelessness, or need urgent housing support, or further advice, you can also contact organisations like Shelter or CitizenAdvice. <br>- Shelter England: <a href='https://england.shelter.org.uk/get_help' target='_blank' rel='noopener noreferrer' >https://england.shelter.org.uk/get_help</a> <br>- Shelter Scotland:  <a href='https://scotland.shelter.org.uk/about_us/contact_us' target='_blank' rel='noopener noreferrer'>https://scotland.shelter.org.uk/about_us/contact_us</a> <br>- Citizen Advice (England): <a href='https://www.citizensadvice.org.uk/about-us/information/chat-with-an-adviser-online/' target='_blank' rel='noopener noreferrer'>https://www.citizensadvice.org.uk/about-us/information/chat-with-an-adviser-online/</a> <br>- Citizen Advice Scotland <a href='https://www.citizensadvice.org.uk/scotland/housing/' target='_blank' rel='noopener noreferrer'>https://www.citizensadvice.org.uk/scotland/housing/</a> ",
  },
  not_eligible_9: {
    text: "You do not meet the minimum requirements to be eligible for housing assistance at the moment",
    additional_text:
      "You cannot apply for social housing if you a person/dependant of a person<br>- who has a refugee status abroad<br>- former asylum seeker who has been instructed to move but failed to do so (failed asylum seeker)<br>- is in the UK in breach of UK immigration laws<br>- failed assylum seaker with dependant children?<br>",
    todo: "If you are facing homelessness, or need urgent housing support, or further advice, you can also contact organisations like Shelter or CitizenAdvice. <br>- Shelter England: <a href='https://england.shelter.org.uk/get_help' target='_blank' rel='noopener noreferrer' >https://england.shelter.org.uk/get_help</a> <br>- Shelter Scotland:  <a href='https://scotland.shelter.org.uk/about_us/contact_us' target='_blank' rel='noopener noreferrer'>https://scotland.shelter.org.uk/about_us/contact_us</a> <br>- Citizen Advice (England): <a href='https://www.citizensadvice.org.uk/about-us/information/chat-with-an-adviser-online/' target='_blank' rel='noopener noreferrer'>https://www.citizensadvice.org.uk/about-us/information/chat-with-an-adviser-online/</a> <br>- Citizen Advice Scotland <a href='https://www.citizensadvice.org.uk/scotland/housing/' target='_blank' rel='noopener noreferrer'>https://www.citizensadvice.org.uk/scotland/housing/</a> ",
  },
  not_eligible_13: {
    text: "You do not meet the minimum requirements to be eligible for housing assistance at the moment",
    additional_text:
      "You cannot apply for social housing if you dont have access to public funds while on a British National Overseas Visa<br>However, you may be able to apply for a 'change of conditions' to access public funds if you meet certain criteria.<br><br>Please see the highlighted text on this website: <a href='https://www.gov.uk/government/publications/public-funds/public-funds-accessible#:~:text=For%20this%20reason%2C%20the%20majority,affecting%20their%20income%20or%20expenditure' target='_blank' rel='noopener noreferrer'>https://www.gov.uk/government/publications/public-funds/public-funds-accessible#:~:text=For%20this%20reason%2C%20the%20majority,affecting%20their%20income%20or%20expenditure</a>",
    todo: "If you are facing homelessness, or need urgent housing support, or further advice, you can also contact organisations like Shelter or CitizenAdvice. <br>- Shelter England: <a href='https://england.shelter.org.uk/get_help' target='_blank' rel='noopener noreferrer' >https://england.shelter.org.uk/get_help</a> <br>- Shelter Scotland:  <a href='https://scotland.shelter.org.uk/about_us/contact_us' target='_blank' rel='noopener noreferrer'>https://scotland.shelter.org.uk/about_us/contact_us</a> <br>- Citizen Advice (England): <a href='https://www.citizensadvice.org.uk/about-us/information/chat-with-an-adviser-online/' target='_blank' rel='noopener noreferrer'>https://www.citizensadvice.org.uk/about-us/information/chat-with-an-adviser-online/</a> <br>- Citizen Advice Scotland <a href='https://www.citizensadvice.org.uk/scotland/housing/' target='_blank' rel='noopener noreferrer'>https://www.citizensadvice.org.uk/scotland/housing/</a> ",
  },
  not_eligible_14: {
    text: "You do not meet the minimum requirements to be eligible for housing assistance at the moment",
    additional_text:
      "You cannot apply for social housing if you dont have access to public funds",
    todo: "If you are facing homelessness, or need urgent housing support, or further advice, you can also contact organisations like Shelter or CitizenAdvice. <br>- Shelter England: <a href='https://england.shelter.org.uk/get_help' target='_blank' rel='noopener noreferrer' >https://england.shelter.org.uk/get_help</a> <br>- Shelter Scotland:  <a href='https://scotland.shelter.org.uk/about_us/contact_us' target='_blank' rel='noopener noreferrer'>https://scotland.shelter.org.uk/about_us/contact_us</a> <br>- Citizen Advice (England): <a href='https://www.citizensadvice.org.uk/about-us/information/chat-with-an-adviser-online/' target='_blank' rel='noopener noreferrer'>https://www.citizensadvice.org.uk/about-us/information/chat-with-an-adviser-online/</a> <br>- Citizen Advice Scotland <a href='https://www.citizensadvice.org.uk/scotland/housing/' target='_blank' rel='noopener noreferrer'>https://www.citizensadvice.org.uk/scotland/housing/</a> ",
  },
  caseworker: {
    text: "You have been referred to a case worker",
    additional_text:
      "Based on your answers, it seems we need a caseworker to decide your minimum eligibility.\
<br><br>You can still create a sharecode but you might need to answer additional questions or present additional documents to the caseworker inorder to help them decide if you are eligibile to apply for social housing.",
  },
  caseworker_1: {
    text: "You have been referred to a case worker",
    additional_text:
      "You need to have already resided in the United Kingdom, Republic of Ireland, Isle of Man or Channel Islands for at least 2 years continiously. However, there might be some exceptions. A case worker might be able to assess this further.",
    todo: "You can still create a sharecode but you might need to answer additional questions or present additional documents to the caseworker inorder to help them decide if you are eligibile to apply for social housing. After creating the sharecode, please contact your potential housing provider directly. <br><br>If you are facing homelessness, or need urgent housing support, or further advice, you can also contact organisations like Shelter or CitizenAdvice. <br>- Shelter England: <a href='https://england.shelter.org.uk/get_help' target='_blank' rel='noopener noreferrer' >https://england.shelter.org.uk/get_help</a> <br>- Shelter Scotland:  <a href='https://scotland.shelter.org.uk/about_us/contact_us' target='_blank' rel='noopener noreferrer'>https://scotland.shelter.org.uk/about_us/contact_us</a> <br>- Citizen Advice (England): <a href='https://www.citizensadvice.org.uk/about-us/information/chat-with-an-adviser-online/' target='_blank' rel='noopener noreferrer'>https://www.citizensadvice.org.uk/about-us/information/chat-with-an-adviser-online/</a> <br>- Citizen Advice Scotland <a href='https://www.citizensadvice.org.uk/scotland/housing/' target='_blank' rel='noopener noreferrer'>https://www.citizensadvice.org.uk/scotland/housing/</a> ",
  },
};

const DecisionPage = () => {
  const navigate = useNavigate();
  const { resetAllFields } = useGlobalState();
  //const { status } = location.state || {};
  const { navigateTo, resetAllState, selections } = useNavigation();
  const { status, setAction } = useGlobalState();

  const decision = decisions[status];
  console.log(status);

  const handleNext = () => {
    const userConfirmed = window.confirm(
      "All your previous responses will be lost. Would you like to continue?"
    );

    if (userConfirmed) {
      resetAllFields();
      resetAllState();
      navigate("/");
    }
  };

  const handleCreateShareCode = () => {
    if (
      status === "eligible" ||
      status === "caseworker" ||
      status === "caseworker_1"
    ) {
      setAction("creation");
      navigate("/sharecodeform");
    }
  };

  if (!decision) {
    return <p>Invalid or missing status</p>;
  }

  return (
    <div className="container">
      <Header />
      <h1>Decision: {decision.text}</h1>

      <div style={{ fontSize: "17px" }}>
        <p>
          <strong>What this means for you? </strong>

          <p dangerouslySetInnerHTML={{ __html: decision.additional_text }}></p>
        </p>

        {decision.todo && (
          <>
            <p>
              <strong>What to do next? </strong>
            </p>
            <p dangerouslySetInnerHTML={{ __html: decision.todo }}></p>
          </>
        )}
      </div>

      <div className="button-container">
        <button id="backButton" onClick={() => navigateTo("back")}>
          Back
        </button>
        {[
          "not_eligible",
          "not_eligible_6",
          "not_eligible_3",
          "not_eligible_8",
          "not_eligible_9",
          "not_eligible_13",
          "not_eligible_14",
        ].includes(status) ? (
          <button id="nextButton" onClick={handleNext}>
            Start Over
          </button>
        ) : (
          <button id="nextButton" onClick={handleCreateShareCode}>
            Create Sharecode
          </button>
        )}
      </div>
      {/*<Summary selections={selections} />
      <div className="button-container">
        <button id="backButton" onClick={() => navigateTo("back")}>
          Back
        </button>
        {status === "not_eligible" ? (
          <button id="nextButton" onClick={handleNext}>
            Start Over
          </button>
        ) : (
          <button id="nextButton" onClick={handleCreateShareCode}>
            Create Sharecode
          </button>
        )}
      </div>*/}
    </div>
  );
};

export default DecisionPage;
