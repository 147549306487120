import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import { useNavigation } from "../context/NavigationContext";
import "../styles/main.css";

const LandingPage = () => {
  const { navigateTo } = useNavigation();

  const navigate = useNavigate();

  useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
      navigate(0);
    };

    window.history.pushState(null, null, window.location.href);
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);

  return (
    <div className="landing-page">
      <Header />
      <div className="landing-content-sharecode">
        <h1>IRESHA Sharecode</h1>
        <h3>
          Immigration/Residence Status Eligibility for Social Housing Assistance
          - Sharecode
        </h3>
        <div className="intro-text">
          <p>
            This webapp can be used to generate a sharecode indicating that you
            fulfil the minimum immigration/residence status sigibility
            requirements for social-housing assistance{" "}
          </p>
          <h4>For applicants</h4>
          To create a sharecode, you will be asked a series of questions to
          check if you have the minimum eligiblity for social-housing
          assistance.
          <br></br>
          <br></br>
          There might be additional requirements based on where you live or
          where you want to apply for social housing.
          <p></p>
          <p>
            You will be asked a series of questions.<br></br>- You can click on
            <span className="play-button"></span>
            under each question to see why a question is being asked.
            <br></br>- For certain terms, additional information is available.
            Look for the question mark icon{" "}
            <span className="help-button">?</span> next to these terms.<br></br>
            <br></br>
            Things you might need to complete this:<br></br>
            -An email address to get a one-time code<br></br>
            -Your e-visa or biometric resident permit, or any other document
            related to permission to stay in the UK, if you require such
            permission
          </p>
          <button id="nextButton" onClick={() => navigateTo("createPage1")}>
            Check eligibility and create sharecode
          </button>
          <h4>For housing associations, local authorities or caseworkers</h4>
          Use this service if you want to verify a sharecode to check someone's
          minimum eligibility for social housing
          <p></p>
          <button id="nextButton" onClick={() => navigateTo("verifyPage")}>
            Verify sharecode
          </button>
          <br></br>
          <br></br>
          ----------------------------------------------------------------------------------------------------------------------------
          <p>
            This work was done under the UKRI-funded (EP/W03235X/1,
            EP/W032333/1, EP/W032341/1, EP/ W032058/1, EP/W032082/1) project
            called PRIME (Protecting Minority Ethnic Communities Online). For
            more information, comments or suggestions , please contact Mehdi
            Rizvi (s.rizvi@hw.ac.uk) or Lynne Baillie (l.baillie@hw.ac.uk){" "}
            <br></br>
            <br></br>
            The citation for this work is: Rizvi, M., Baillie, L., Pang, W.,
            Shahandashti, S., Yuan, Y., Ghosh, S., Lewinska, P., Chen, K.,
            Edmondson, A., Anil Kumar, A. S., Jagadeesan, V. B., Jacob, F., &
            Dodd, C. (2025). ”Carefully Connected - Towards Designing More
            Equitable Digital Services”,{" "}
            <a
              href="https://doi.org/10.17861/C1DX-PQ86"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://doi.org/10.17861/C1DX-PQ86
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
